import React from 'react';
import { Text, Icon } from '@blastradius/ui';
import Container from '@customer-web-app/domains/shared/components/container';
import classNames from 'classnames';

const IncidentsHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = (
  props,
) => {
  const defaultClassName =
    'bg-white dark:bg-gray-900 shadow-xl dark:shadow-sm 2.5xl:pl-18 2.5xl:pr-20 2.5xl:flex 2.5xl:justify-between pr-2 lg:pr-0 mb-12';

  const className = classNames(defaultClassName, props.className);

  return (
    <header className={className}>
      <Container>
        <div className="flex items-center justify-between 2.5xl:max-w-[142rem] mx-auto">
          <div className="grid grid-cols-[3.25rem_1fr] items-center gap-9">
            <div className="w-13 h-13 bg-gray-50 dark:bg-gray-950 rounded p-3 flex item-center justify-center">
              <Icon
                name="incidents"
                size={26}
                className="fill-gray-950 dark:fill-white"
              />
            </div>
            <Text
              type="heading"
              as="h1"
              className="h-[6.25rem] flex items-center"
            >
              Incidents
            </Text>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default IncidentsHeader;
