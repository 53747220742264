import React from 'react';
import { Bullet, Card, Text, Pill } from '@blastradius/ui';
import {
  Incident,
  IncidentStatuses,
} from '@customer-web-app/domains/incidents/models/incident';
import incidentTypesCopies from '@customer-web-app/domains/incidents/mappers/incident-types-copies';
import Skeleton from 'react-loading-skeleton';
import bordersMapper from '@customer-web-app/domains/incidents/mappers/borders-mapper';
import typeColorsMapper from '@customer-web-app/domains/incidents/mappers/type-colors-mapper';
import Link from 'next/link';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import getIncidentDetailsEntryPointURL from '@customer-web-app/domains/incident-details/services/get-incident-details-entry-point-url';
import getIncidentStep from '@customer-web-app/domains/shared/services/get-incident-step';
import IncidentDetailsIconContained from '@customer-web-app/domains/incident-details/components/incident-details-icon-contained';

type Props = {
  incident: Incident;
  loading?: boolean;
};

const BulkSmallIncidentDetails: React.FC<{
  incident: Incident;
  loading: boolean;
}> = ({ incident, loading }) => {
  return (
    <div className="flex justify-between">
      <div className="w-[42rem]">
        <header>
          <Text
            type="heading"
            size="x-small"
            className="line-clamp-2 max-w-[35rem]"
            as="h3"
          >
            {!loading ? (
              incident?.incidentName
            ) : (
              <Skeleton width={350} height={20} />
            )}
          </Text>
        </header>
      </div>
    </div>
  );
};

const IncidentBulkCloseCard: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({ incident, loading, ...props }) => {
  const { incidentsEnableSummaryByContext } = useFeatureFlags();

  const incidentDetailsEntryPoint = getIncidentDetailsEntryPointURL(
    incident?.incidentType,
    incidentsEnableSummaryByContext as string[],
  );

  const href = `/incidents/${incident?.id}/${incidentDetailsEntryPoint}`;

  return (
    <Link href={href} passHref legacyBehavior>
      <Card
        {...props}
        border={bordersMapper[incident?.incidentStatus]}
        clickable={!loading}
        className="inline-block w-full p-6 select-none"
        as="a"
        target="_blank"
        background="bg-white dark:bg-gray-800"
      >
        <div className="flex items-center gap-4">
          <IncidentDetailsIconContained
            incident={incident}
            loading={false}
            size="regular"
            className="!shrink-0 !w-13 !h-13"
          />
          <div className="flex flex-col gap-2">
            <header>
              <div className="flex items-center gap-1">
                {!loading &&
                  incident?.incidentStatus === IncidentStatuses.Opened &&
                  incident?.isLive && (
                    <Pill color="red" className="mr-2" size="small">
                      <Bullet color="white" className="mr-1" />
                      <Text type="label-caps" color="text-white">
                        Live
                      </Text>
                    </Pill>
                  )}

                <Text
                  type="label-caps"
                  {...typeColorsMapper[getIncidentStep(incident)]}
                >
                  {!loading ? (
                    incidentTypesCopies[incident?.incidentType]
                  ) : (
                    <Skeleton width={57} height={12} />
                  )}
                </Text>
                <Text type="body" color="text-gray-500" className="ml-2">
                  {!loading ? (
                    incident?.incidentNumber
                  ) : (
                    <Skeleton width={57} height={12} />
                  )}
                </Text>
              </div>
            </header>

            <BulkSmallIncidentDetails incident={incident} loading={loading} />
          </div>
        </div>
      </Card>
    </Link>
  );
};

export default IncidentBulkCloseCard;
