import { Card } from '@blastradius/ui';
import { Incident } from '../models/incident';

const bordersMapper: {
  [key in Incident['incidentStatus']]: React.ComponentProps<
    typeof Card
  >['border'];
} = {
  opened: 'red',
  closed: 'gray',
  pending: 'gray',
  remediated: 'green',
};

export default bordersMapper;
