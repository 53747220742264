import {
  Banner,
  Button,
  ButtonGhost,
  Dialog,
  Icon,
  Number,
  ProgressBar,
  Text,
} from '@blastradius/ui';
import { DialogProps } from '@blastradius/ui/core-components/dialog';
import { IncidentStatusUpdateResponse } from '@customer-web-app/domains/incidents/hooks/use-incident-status';
import {
  Incident,
  IncidentStatuses,
} from '@customer-web-app/domains/incidents/models/incident';
import api from '@customer-web-app/domains/shared/api';
import _ from 'lodash';
import { useState } from 'react';
import IncidentBulkCloseCard from '@customer-web-app/domains/incidents/components/incidents-bulk-close-card';
import classNames from 'classnames';
import React from 'react';
import useDialog from '@blastradius/ui/hooks/use-dialog';
import IncidentStatusChangeAddCommentDialog from '@customer-web-app/domains/incidents/components/incident-status-change-add-comment-dialog';

type Props = {
  incidents: Incident[];
  closedAsFalsePositive: boolean;
  closeDialogAndResetState?: (
    successIncidents: Incident[],
    closedAsFalsePositive: boolean,
  ) => void;
};

function BulkCloseIncidentDialogContent({
  incidents,
  closeDialogAndResetState,
  closedAsFalsePositive,
}: Props) {
  const [step, setStep] = useState(0);
  const [failedIncidents, setFailedIncidents] = useState([]);
  const [successIncidents, setSuccessIncidents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { openDialog } = useDialog();

  const currentProgress = successIncidents.length + failedIncidents.length;
  const steps = [
    {
      name: 'Confirmation',
      content: (
        <>
          {isLoading ? (
            <div className="h-full w-full flex flex-col justify-center items-center">
              <Icon
                name="incidents"
                className="fill-gray-950 dark:fill-white mb-4"
                size={32}
              />
              <div className="flex items-baseline gap-2 mb-6">
                <Text type="body" size="regular" as="span">
                  Closing Incidents
                </Text>
                <div className="flex gap-1">
                  <Text
                    type="heading"
                    size="large"
                    className="!font-blast"
                    as="span"
                  >
                    <Number value={currentProgress} />
                  </Text>
                  <Text
                    type="heading"
                    size="large"
                    className="!font-blast"
                    as="span"
                    color="text-gray-500"
                  >
                    /
                  </Text>
                  <Text
                    type="heading"
                    size="large"
                    className="!font-blast"
                    as="span"
                    color="text-gray-500"
                  >
                    <Number value={incidents.length} />
                  </Text>
                </div>
              </div>
              <ProgressBar
                progress={currentProgress / incidents.length}
                color="neutral"
                height="h-1.5"
                className="w-[22.25rem]"
              />
            </div>
          ) : (
            <div
              className="flex-grow flex flex-col gap-2 w-full overflow-x-hidden overflow-y-scroll pb-8 bg-gray-50 dark:bg-gray-925"
              style={{ scrollbarGutter: 'stable' }}
            >
              {incidents.map((incident) => (
                <IncidentBulkCloseCard key={incident?.id} incident={incident} />
              ))}
            </div>
          )}
        </>
      ),
      actions: {},
    },
    {
      name: 'Check Failures',
      content: (
        <>
          <Banner type="error">
            We failed to close the following incidents
          </Banner>
          <div
            className="flex-grow flex flex-col gap-2 w-full overflow-x-hidden overflow-y-scroll pb-8 bg-gray-50 dark:bg-gray-925"
            style={{ scrollbarGutter: 'stable' }}
          >
            {failedIncidents.map((incident) => (
              <IncidentBulkCloseCard key={incident?.id} incident={incident} />
            ))}
          </div>
        </>
      ),
    },
  ];

  async function handleBulkIncidentsClose() {
    setIsLoading(true);
    const chunks = _.chunk(incidents, 5);
    let failedFlag = false;
    const totalSuccess = [];
    for (const chunk of chunks) {
      const results = await Promise.allSettled(
        chunk.map(async (incident: Incident) => {
          await api.post<IncidentStatusUpdateResponse>(
            `/incidents/${incident.id}/status/close`,
            { closedAsFalsePositive },
          );
          return incident;
        }),
      );
      const successResults = results
        .filter((o) => o.status === 'fulfilled')
        .map((o) => (o as PromiseFulfilledResult<Incident>).value);
      totalSuccess.push(...successResults);
      const successIds = successResults.map(({ id }) => id);
      const failed = chunk.filter(
        (incident: Incident) => !successIds.includes(incident.id),
      );
      if (failed.length > 0) {
        failedFlag = true;
      }
      setSuccessIncidents((c) => {
        return [...c, ...successResults];
      });
      setFailedIncidents((c) => [...c, ...failed]);
    }
    if (failedFlag) {
      setIsLoading(false);
      setStep(1);
      return;
    }

    /* wait for index propagation
       min of 10s
       max of 30s
       multiple of success length otherwise */
    await new Promise((r) =>
      setTimeout(r, 1_000 * Math.min(Math.max(totalSuccess.length, 10), 30)),
    );

    openDialog(IncidentStatusChangeAddCommentDialog, {
      incidents: totalSuccess,
      incidentStatus: IncidentStatuses.Closed,
      closedAsFalsePositive,
      bulkActionCloseDialog: () =>
        closeDialogAndResetState(totalSuccess, closedAsFalsePositive),
      observables: [],
      top: '!top-[31%]',
    });
  }

  const incidentCopy = incidents.length > 1 ? 'incidents' : 'incident';

  return (
    <>
      {
        <div className="max-h-full p-6 flex flex-col items-center justify-between h-full space-y-4">
          <div className="pt-7 flex flex-col items-center gap-7 w-full flex-grow min-h-0">
            <div className="flex flex-col items-start gap-4 w-full max-h-full h-full">
              <div className="flex flex-col gap-2">
                <Text type="heading" size="regular">
                  {isLoading || failedIncidents.length > 0
                    ? 'Closing Incidents'
                    : 'Are you sure?'}
                </Text>
                <Text
                  type="body"
                  size="regular"
                  color="text-gray-600 dark:text-gray-500"
                  className="mb-1"
                >
                  You are about to close <b>{incidents.length}</b>{' '}
                  {incidentCopy}
                </Text>
              </div>

              <hr className="w-full h-px border-gray-200 dark:border-gray-800" />

              {steps[step].content}
            </div>
          </div>
          <div className="flex flex-col align-end w-full space-y-3">
            <hr className="w-[calc(100%+3rem)] -ml-6 h-px border-gray-200 dark:border-gray-800" />

            <div className="w-full grid grid-cols-[1fr_1fr] gap-4">
              {!isLoading ? (
                <Dialog.Deactivate
                  closeDialog={() =>
                    closeDialogAndResetState([], closedAsFalsePositive)
                  }
                >
                  <ButtonGhost disabled={isLoading} size="small">
                    Cancel
                  </ButtonGhost>
                </Dialog.Deactivate>
              ) : (
                <div></div>
              )}

              <Button
                size="small"
                color="white"
                disabled={isLoading}
                loading={isLoading}
                onClick={handleBulkIncidentsClose}
                className={classNames({
                  'self-end': isLoading,
                })}
              >
                {failedIncidents.length > 0 ? 'Try Again' : 'Close Incidents'}
              </Button>
            </div>
          </div>
        </div>
      }
    </>
  );
}

function BulkCloseIncidentDialog({
  incidents,
  closeDialogAndResetState,
  closedAsFalsePositive,
}: Props & Omit<DialogProps, 'closeDialog'>) {
  return (
    <Dialog
      color="gray"
      width="w-[46.25rem] !top-[20%]"
      height="h-[38.75rem]"
      withGradient={false}
    >
      <BulkCloseIncidentDialogContent
        incidents={incidents}
        closeDialogAndResetState={closeDialogAndResetState}
        closedAsFalsePositive={closedAsFalsePositive}
      />
    </Dialog>
  );
}

export default BulkCloseIncidentDialog;
