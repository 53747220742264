import useDialog from '@blastradius/ui/hooks/use-dialog';
import { ButtonTransparent, Table, TableContext } from '@blastradius/ui';
import useToast from '@blastradius/ui/hooks/use-toast';
import BulkCloseIncidentDialog from '@customer-web-app/domains/incidents/components/bulk-close-dialog';
import IncidentCard from '@customer-web-app/domains/incidents/components/incident-card';
import {
  FetchMoreType,
  IncidentsResponse,
} from '@customer-web-app/domains/incidents/hooks/use-incidents';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import IncidentFilterService, {
  IncidentFilterParams,
} from '@customer-web-app/domains/incidents/services/incident-filter-service';
import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import classNames from 'classnames';
import React from 'react';

type Props = {
  incidents: Incident[];
  loading?: boolean;
  loadingCount?: number;
  totalIncidents?: number;
  formValues?: IncidentFilterParams;
  getIncidentsNextPage?: (page: number) => void;
  fetchMoreIncidents?: (
    filter: FetchMoreType,
  ) => Promise<{ data: IncidentsResponse }>;
  goToPage?: (page: number) => void;
};

const MAX_SELECTION_BULK = 100;
const IncidentsCards: React.FC<
  Props & React.HTMLAttributes<HTMLDivElement>
> = ({
  incidents,
  loading = false,
  loadingCount = 4,
  totalIncidents = null,
  formValues = null,
  getIncidentsNextPage,
  fetchMoreIncidents,
  goToPage,
  ...props
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { incidentsEnableBulkOperations } = useFeatureFlags();

  const { uncheckAllRows } = React.useContext(TableContext);

  const { notification } = useToast();

  const showCheckboxes = incidentsEnableBulkOperations || false;

  const hasOpenIncidents =
    formValues?.incidentFilter?.incidentPhase?.length > 0;

  const openIncidentsCount = incidents.filter(
    (x) => x.incidentStatus === 'opened',
  ).length;

  const showTableHeader =
    (loading || incidents.length > 0) && showCheckboxes && hasOpenIncidents;

  const closeBulkCloseIncidentDialog = async (
    successIncidents,
    closedAsFalsePositive,
  ) => {
    const closedAsFalsePositiveText = closedAsFalsePositive
      ? 'as false positive'
      : 'as closed';

    closeDialog();

    if (!successIncidents.length) {
      return;
    }

    const message =
      successIncidents.length > 1
        ? `${successIncidents.length} incidents were marked ${closedAsFalsePositiveText}`
        : `1 incident was marked ${closedAsFalsePositiveText}`;
    getIncidentsNextPage(0);
    goToPage(0);
    uncheckAllRows();
    notification({
      title: 'Incidents Closed',
      message: message,
      icon: closedAsFalsePositive ? 'false-positive' : 'incident-closed',
      iconContained: false,
      position: 'bottom-left',
      options: [{ type: 'dismiss' }],
    });
  };

  return (
    <div {...props} className={`${classNames('grid gap-4', props.className)}`}>
      <Table
        selectionType="multiple"
        cols={[
          {
            label: 'Incidents',
            width: '1fr',
          },
        ]}
        {...(showCheckboxes
          ? {
              onAllRowsToggled: () => {},
              withCheckboxes: true,
              maxSelectionCount: MAX_SELECTION_BULK,
              maxResultsPerPage: openIncidentsCount || loadingCount,
              showAllCheckboxesWhenChecked: true,
              totalAvailable: totalIncidents,
            }
          : {})}
        comparisonKey="id"
      >
        {showTableHeader && (
          <Table.Header
            withSelectAll
            itemDescription="incident"
            itemDescriptionOnPlural="incidents"
            columnClassName="font-bold"
            className={classNames('py-1 h-12', {
              'pointer-events-none': loading,
            })}
            filterKey="incidentStatus"
            comparisonValue="opened"
            maxSelectableCount={openIncidentsCount}
            areThereMorePages={totalIncidents > openIncidentsCount}
            fetchAllAvailableKeys={async () => {
              const vars: FetchMoreType = {
                variables: {
                  filters:
                    IncidentFilterService.buildIncidentFilter(formValues),
                  pagination: {
                    from: 0,
                    size: MAX_SELECTION_BULK,
                  },
                },
              };
              vars.variables.filters.incidentFilter.incidentStatus = ['opened'];
              const { data } = await fetchMoreIncidents(vars);
              const values = [].concat(data.searchV2.items);
              return values;
            }}
            actions={({ values }) => {
              return (
                <>
                  <ButtonTransparent
                    icon="incident-closed"
                    aria-label="Mark as Closed"
                    className="p-2 h-9 shrink-0 !w-36"
                    onClick={async () => {
                      openDialog(BulkCloseIncidentDialog, {
                        incidents: values,
                        closedAsFalsePositive: false,
                        closeDialogAndResetState: closeBulkCloseIncidentDialog,
                      });
                    }}
                  >
                    Mark as Closed
                  </ButtonTransparent>

                  <ButtonTransparent
                    icon="false-positive"
                    aria-label="Mark as False Positive"
                    className="p-2 h-9 shrink-0 !w-44"
                    onClick={async () => {
                      openDialog(BulkCloseIncidentDialog, {
                        incidents: values,
                        closedAsFalsePositive: true,
                        closeDialogAndResetState: closeBulkCloseIncidentDialog,
                      });
                    }}
                  >
                    Mark as False Positive
                  </ButtonTransparent>
                </>
              );
            }}
          />
        )}
        <Table.Body className="gap-2">
          {!loading
            ? incidents.map((incident, key) => (
                <Table.Row
                  className="!py-0 !pl-0 !pr-0 grid grid-cols-[0_1fr] relative"
                  checkboxClassName="absolute left-5 top-4 z-10"
                  value={incident}
                  key={incident.id}
                  showCheckbox={
                    incidentsEnableBulkOperations &&
                    incident.incidentStatus === 'opened'
                  }
                  openCheckedInNewTab
                >
                  <IncidentCard
                    key={`${incident.incidentNumber}_${key}`}
                    incident={incident}
                  />
                </Table.Row>
              ))
            : Array.from({ length: loadingCount }).map((_, index) => (
                <IncidentCard
                  key={index}
                  data-testid="incidents-cards-skeleton-loader"
                  incident={null}
                  loading
                />
              ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default IncidentsCards;
